<template>
<div class="view-home">
	<div class="container">
    <div class="row about-page pb-5">
      <div class="col-md-12">
        <div class="breadcrumb px-0">
          <router-link to="/">{{ $t('breadcrumb.main-page') }}</router-link>
          <router-link to="/faq" class="active">{{ $t('breadcrumb.faq') }}</router-link>
        </div>
        <div class="row">
          <div class="col-md-12 pb-4">
            <h1 class="title about-title mb-3">{{  $t('faq.title') }}</h1>
          </div>
<!--          <div class="col-md-4 sidebar">-->
<!--            <ul class="sidebar-menu">-->
<!--              <li><a href="#">Общие вопросы</a></li>-->
<!--              <li class="active"><a href="#">Олимпиада Myn Bala</a></li>-->
<!--              <li><a href="#">Профориентация Qabilet</a></li>-->
<!--              <li><a href="#">Онлайн школа Birge Oqy</a></li>-->
<!--            </ul>-->
<!--          </div>-->
          <div class="col-md-12 content">
            <div id="accordion">
              <div class="card" v-for="(data, key) in accordionData" :key="data.title + key">
                <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
                  <h5 class="mb-0">
                    <button class="btn btn-link" :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                      {{ data.title }}
                    </button>
                  </h5>
                </div>

                <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse" :aria-labelledby="'heading' + key" data-parent="#accordion">
                  <div class="card-body pt-0">
                    <hr class="mt-0">
                    <div class="faq_answers" v-html="$t(data.content)"></div>
                  </div>
                </div>
              </div>

              <div class="card" :key="rulesProcKeyRu" v-if="this.$i18n.locale === 'ru'">
                <div class="card-header" @click="accordion = 'collapse' + rulesProcKeyRu" :id="'heading' + rulesProcKeyRu">
                  <h5 class="mb-0">
                    <button class="btn btn-link" :class="{'active': accordion == 'collapse' + rulesProcKeyRu}"
                            data-toggle="collapse" :data-target="'#collapse' + rulesProcKeyRu" aria-expanded="false"
                            :aria-controls="'collapse' + rulesProcKeyRu">
                      {{this.$t('faq.question-18')}}
                    </button>
                  </h5>
                </div>

                <div :id="'collapse' + rulesProcKeyRu" :class="{'show': accordion == 'collapse' + rulesProcKeyRu}"
                     class="collapse" :aria-labelledby="'heading' + rulesProcKeyRu" data-parent="#accordion">
                  <div class="card-body pt-0">
                    <hr class="mt-0">
                    <div class="faq_answers">
                      ❗️Важно! Мы используем камеру и микрофон для прокторинга. Все нарушения фиксируются. На экране должны быть только задачи олимпиады.
                    </div>
                    <div class="faq_answers" style="padding-bottom: 10px">
                      Что нельзя?
                    </div>

                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Уходить из видимости камеры
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Отворачиваться от камеры
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Присутствие посторонних лиц
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Сворачивать страницу с задачами
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Открывать другие вкладки, окна или приложения
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Использовать интернет для посторонних целей
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Закрывать или заклеивать камеру
                    </div>

                    <div class="faq_answers" style="padding-bottom: 10px; padding-top: 20px">
                      Что можно?
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ✅ Находиться в поле видимости камеры
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ✅ Быть лицом к камере
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ✅ Выполнять тест самостоятельно
                    </div>

                    <div class="faq_answers" style="padding-bottom: 10px; padding-top: 20px">
                      💡 Подготовьте рабочее место:
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      • Проверьте работу камеры и микрофона
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      • Убедитесь, что вас хорошо видно
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      • Уберите всё лишнее со стола
                    </div>

                  </div>
                </div>
              </div>

              <div class="card" :key="rulesProcKeyKz" v-if="this.$i18n.locale === 'kz'">
                <div class="card-header" @click="accordion = 'collapse' + rulesProcKeyKz" :id="'heading' + rulesProcKeyKz">
                  <h5 class="mb-0">
                    <button class="btn btn-link" :class="{'active': accordion == 'collapse' + rulesProcKeyKz}"
                            data-toggle="collapse" :data-target="'#collapse' + rulesProcKeyKz" aria-expanded="false"
                            :aria-controls="'collapse' + rulesProcKeyKz">
                      {{this.$t('faq.question-18')}}
                    </button>
                  </h5>
                </div>

                <div :id="'collapse' + rulesProcKeyKz" :class="{'show': accordion == 'collapse' + rulesProcKeyKz}"
                     class="collapse" :aria-labelledby="'heading' + rulesProcKeyKz" data-parent="#accordion">
                  <div class="card-body pt-0">
                    <hr class="mt-0">
                    <div class="faq_answers">
                      ❗️ Маңызды! Біз камераны және микрофонды прокторинг үшін қолданамыз. Барлық бұзушылықтар тіркеледі. Экранда тек олимпиада тапсырмалары болуы керек.
                    </div>
                    <div class="faq_answers" style="padding-bottom: 10px">
                      Не істеуге болмайды?
                    </div>

                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Камераның көру аймағынан шығу
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Камерадан бетті бұру
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Бөгде адамдардың қатысуы
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0">
                      ❌ Олимпиада тапсырмалары бар бетті жабу
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ❌ Басқа қойындыларды, терезелерді немесе қосымшаларды ашу
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ❌ Интернетті басқа мақсаттарда пайдалану
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ❌ Камераны жабу немесе желімдеу
                    </div>

                    <div class="faq_answers" style="padding-bottom: 10px; padding-top: 20px">
                      Не істеуге болады?
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ✅ Камераның көру аймағында болу
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ✅ Камераға қарап отыру
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      ✅ Тесті өз бетімен орындау
                    </div>

                    <div class="faq_answers" style="padding-bottom: 10px; padding-top: 20px">
                      💡 Жұмыс орнын дайындаңыз:
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      • Камера мен микрофонның жұмысын тексеріңіз
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      • Сіздің бетіңіз анық көрінетініне көз жеткізіңіз
                    </div>
                    <div class="faq_answers" style="padding-bottom: 0;">
                      • Үстелден қажетсіз заттарды алып тастаңыз
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</div>
</template>
<script>
export default {
  data() {
    return {
      accordion: 'collapse0',
      rulesProcKeyRu: 17,
      rulesProcKeyKz: 18,
      accordionData: [
        {
          'title': this.$t('faq.question-1'),
          'content': this.$t('faq.answer-1'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-2'),
          'content': this.$t('faq.answer-2'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-3'),
          'content': this.$t('faq.answer-3'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-4'),
          'content': this.$t('faq.answer-4'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-5'),
          'content': this.$t('faq.answer-5'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-6'),
          'content': this.$t('faq.answer-6'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-7'),
          'content': this.$t('faq.answer-7'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-8'),
          'content': this.$t('faq.answer-8'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-10'),
          'content': this.$t('faq.answer-10'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-11'),
          'content': this.$t('faq.answer-11'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-12'),
          'content': this.$t('faq.answer-12'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-13'),
          'content': this.$t('faq.answer-13'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-14'),
          'content': this.$t('faq.answer-14'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-15'),
          'content': this.$t('faq.answer-15'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-16'),
          'content': this.$t('faq.answer-16'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-17'),
          'content': this.$t('faq.answer-17'),
          'content2': ''
        },
      ],
    }
  },
}
</script>
